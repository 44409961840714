import React, { useState, useEffect } from 'react';
import {
  VStack,
  Text,
  SimpleGrid,
  Divider,
  useColorModeValue,
  Icon,
  Center,
  Button,
  ButtonGroup
} from '@chakra-ui/react';
import { FaFileMedical } from 'react-icons/fa';
import FileUploadBox from './FileUploadBox';
import axios from 'axios';

const Dashboard = ({ walletConnected, walletAddress }) => {
  const [medicalRecords, setMedicalRecords] = useState([]);
  const cardBg = useColorModeValue('gray.700', 'gray.600');
  const textColor = useColorModeValue('gray.100', 'gray.200');
  const borderColor = useColorModeValue('gray.300', 'gray.600');


  const handleUploadSuccess = () => {
    fetchMedicalRecords();
  };

  const fetchMedicalRecords = async () => {
    if (walletAddress) {
      try {
        const response = await axios.get(`https://blockchain-for-medicine-production.up.railway.app/api/files/${walletAddress}`);
        setMedicalRecords(response.data.map((file, index) => ({
          id: index,
          name: file.filename,
          blockNumber: file.blockNumber
        })));
      } catch (error) {
        console.error("Error fetching medical records", error);

      }
    }
  };

  useEffect(() => {
    if (walletConnected) {
      fetchMedicalRecords();
    }
  }, [walletConnected, walletAddress]);


  const handleDownload = (fileName) => {
    const downloadUrl = `https://ubchospital.s3.ca-central-1.amazonaws.com/${fileName}`;
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleRetireveKey = async (filename, walletAddress, blockNumber) => {
    console.log(walletAddress, filename, blockNumber)
    //send request to /key endpoint with the inputs as body or query
    const response = await axios.post('https://blockchain-for-medicine-production.up.railway.app/keys', {
      walletAddress,
      blockNumber
    });
    // window.alert(response.data.key);
    console.log(response.data.key[0]);
    await downloadAndDecryptFile(filename, response.data.key[0], response.data.key[1]);
  }

  async function downloadAndDecryptFile(fileName, keyHex, ivHex) {
    const encryptedFileUrl = `https://ubchospital.s3.ca-central-1.amazonaws.com/${fileName}`;
    const response = await fetch(encryptedFileUrl);
    const encryptedData = await response.arrayBuffer(); // Get the encrypted file as an ArrayBuffer

    const keyBuffer = Uint8Array.from(Buffer.from(keyHex, 'hex')).buffer;
    const iv = Uint8Array.from(Buffer.from(ivHex, 'hex'));

    const cryptoKey = await window.crypto.subtle.importKey(
      "raw",
      keyBuffer,
      { name: "AES-CBC", length: 256 },
      false, // Non-extractable
      ["decrypt"]
    );

    const decryptedData = await window.crypto.subtle.decrypt(
      { name: "AES-CBC", iv },
      cryptoKey,
      encryptedData
    );

    const blob = new Blob([decryptedData], { type: "application/octet-stream" });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
    a.remove();

    console.log("Done decrypting and downloading file")
  }


  const RecordCard = ({ record }) => (
    <Center
      bg={cardBg}
      p={5}
      shadow="lg"
      borderWidth="1px"
      borderRadius="lg"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      height="auto"
    >
      <VStack>
        <Icon as={FaFileMedical} w={10} h={10} color={textColor} />
        <Text fontWeight="bold" color={textColor}>{record.name}</Text>
        <Text fontSize="sm" color={textColor}>blockNumber: {record.blockNumber}</Text>
      </VStack>

      <VStack width="100%" mt={4}>
        <Divider />
        <ButtonGroup variant="outline" spacing={4} mt={4} justifyContent="center" width="100%">
          <Button colorScheme="blue" width="40%" onClick={() => handleRetireveKey(record.name, walletAddress, record.blockNumber)}>Retrieve Key</Button>
          <Button colorScheme="green" width="40%" onClick={() => handleDownload(record.name)}>Download</Button>
        </ButtonGroup>
      </VStack>
    </Center>
  );


  return (
    <VStack spacing={5} p={5}>
      <Text fontSize="2xl" fontWeight="bold">Your Medical Records</Text>
      {walletConnected ? (
        <>
          <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} spacing={5}>
            {medicalRecords.map(record => <RecordCard key={record.id} record={record} />)}
            {console.log(medicalRecords)}
          </SimpleGrid>
          <Divider my={5} />
          <FileUploadBox walletAddress={walletAddress} onUploadSuccess={handleUploadSuccess} />
        </>
      ) : (
        <Text color={borderColor}>Please connect your wallet to view and upload records.</Text>
      )}
    </VStack>
  );
};

export default Dashboard;
