import React, { useState, useEffect } from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';
import Header from './components/Header';
import Dashboard from './components/Dashboard';
import Footer from './components/Footer';
import { ethers } from 'ethers';
import Web3 from "web3";
import { Web3Auth } from "@web3auth/modal";
import { CHAIN_NAMESPACES, IProvider } from "@web3auth/base";

// Web3Auth Setup:
const clientId =
  "BOWHpk7hkkkIk-TncRS7vul482IPQ7yNx83Sy_mv2TdVTqzbFSSh2-jjwMSKyFsXf3p0qBaA_kxnJsHNufuJtdg";
const chainConfig = {
  chainNamespace: CHAIN_NAMESPACES.EIP155,
  chainId: "0x1", // Please use 0x1 for Mainnet
  rpcTarget: "https://rpc.ankr.com/eth",
  displayName: "Ethereum Mainnet",
  blockExplorer: "https://etherscan.io/",
  ticker: "ETH",
  tickerName: "Ethereum",
};
const web3auth = new Web3Auth({
  clientId,
  chainConfig,
  web3AuthNetwork: "sapphire_devnet",
});


function App() {
  // Web3Auth Setup:
  const [web3authProvider, setWeb3authProvider] = useState(null);
  const [web3authLoggedIn, setWeb3authLoggedIn] = useState(false);
  const [choice, setChoice] = useState(false);

  useEffect(() => {
    const init = async () => {
      try {
        await web3auth.initModal();
        setWeb3authProvider(web3auth.provider);

        if (web3auth.connected) {
          setWeb3authLoggedIn(true);
        }
      } catch (error) {
        console.error(error);
      }
    };

    init();
  }, []);

  const [web3authConnected, setWeb3authConnected] = useState(false);
  const [web3authAddress, setWeb3authAddress] = useState('');

  const login = async () => {
    try {
      const web3authProvider = await web3auth.connect();
      console.log(web3authProvider);
      if (web3auth.connected) {
        setWeb3authLoggedIn(true);
      }
      setWeb3authProvider(web3authProvider);
      console.log(web3authProvider)
      await getAccounts();
    } catch (error) {
      console.error(error);
    }
  };

  const logout = async () => {
    await web3auth.logout();
    setWeb3authConnected(false);
    setWalletConnected(false);
    setWeb3authAddress('');
    setWalletAddress('');
    setWeb3authProvider(null);
    setWeb3authLoggedIn(false);
    setChoice(false);
    console.log("logged out");
  };

  const getAccounts = async () => {
    if (!web3authProvider) {
      console.log("provider not initialized yet");
    }
    const web3 = new Web3(web3authProvider);
    // Get user's Ethereum public address
    const address = await web3.eth.getAccounts();
    console.log(address);
    setWeb3authConnected(true)
    setWalletConnected(true);
    setWeb3authAddress(address[0]);
    setWalletAddress(address[0]);
    setChoice(true);
  };


  const [walletConnected, setWalletConnected] = useState(false);
  const [walletAddress, setWalletAddress] = useState('');
  const appBg = useColorModeValue('gray.100', 'gray.800');

  const handleWalletConnect = async () => {
    if (window.ethereum) {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send('eth_requestAccounts', []);
        const signer = provider.getSigner();
        const address = await signer.getAddress();

        setWalletConnected(true);
        setWalletAddress(address);
      } catch (error) {
        if (error.message.includes('Talisman extension has not been configured')) {
          console.error("Talisman Error:", error);
          alert('Talisman extension has not been configured yet. Please continue with onboarding.');
        } else {
          console.error("Error connecting to MetaMask", error);
        }
      }
    } else {
      alert('MetaMask is not installed. Please install it to use this feature.');
    }
  };

  const handleWalletDisconnect = () => {
    setWalletConnected(false);
    setWalletAddress('');
  };


  return (
    <Box bg={appBg} minH="100vh">
      <Header
        onConnect={handleWalletConnect}
        onDisconnect={handleWalletDisconnect}
        walletConnected={walletConnected}
        walletAddress={walletAddress}
        // Web3Auth Setup:
        onWeb3authConnect={login}
        onWeb3authDisconnect={logout}
        web3authConnected={web3authConnected}
        web3authAddress={web3authAddress}

        choice={choice}
      />
      <Dashboard walletConnected={walletConnected} walletAddress={walletAddress} />
      <Footer />
    </Box>
  );
}

export default App;
