import React from 'react';
import { Flex, Heading, Spacer, useColorModeValue, IconButton, Text, Button } from '@chakra-ui/react';
import { FaHospitalSymbol, FaSun, FaMoon } from 'react-icons/fa';
import { useColorMode } from '@chakra-ui/color-mode';

const Header = ({ onConnect, onDisconnect, walletConnected, walletAddress, onWeb3authConnect, onWeb3authDisconnect, web3authConnected, web3authAddress, choice }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const bg = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.600', 'white');

  const formatAddress = (address) => {
    return `${address.substring(0, 6)}...${address.substring(address.length - 4)}`;
  };

  return (
    <Flex
      as="header"
      width="full"
      align="center"
      justifyContent="space-between"
      padding={4}
      bg={bg}
      color={textColor}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
    >
      <Flex align="center">
        <FaHospitalSymbol size="2em" />
        <Heading as="h1" size="lg" marginLeft={2}>
          iHealth Chain MVP
        </Heading>
      </Flex>
      <Spacer />
      <Flex align="center">
        <IconButton
          icon={colorMode === 'light' ? <FaMoon /> : <FaSun />}
          onClick={toggleColorMode}
          variant="ghost"
          marginRight={4}
        />
        {(web3authConnected && choice) ? (
          <>
            <Text mr={4}>{formatAddress(web3authAddress)}</Text>
            <Button colorScheme="red" onClick={onWeb3authDisconnect}>Disconnect</Button>
          </>
        ) : walletConnected ? (
          <></>
        ) : (
          <Button marginRight={1} colorScheme="teal" onClick={onWeb3authConnect}>Connect via Gmail</Button>
        )}
        {(walletConnected && !choice) ? (
          <>
            <Text mr={4}>{formatAddress(walletAddress)}</Text>
            <Button marginLeft={1} colorScheme="red" onClick={onDisconnect}>Disconnect</Button>
          </>
        ) : web3authConnected ? (
          <></>
        ) : (
          <Button marginLeft={1} colorScheme="teal" onClick={onConnect}>Connect Wallet</Button>
        )}
      </Flex>
    </Flex>
  );
};

export default Header;
