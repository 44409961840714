import React, { useState } from 'react';
import { VStack, Center, Icon, Text, useToast, useColorModeValue } from '@chakra-ui/react';
import { FaCloudUploadAlt } from 'react-icons/fa';
import axios from 'axios';

const FileUploadBox = ({ walletAddress, onUploadSuccess }) => {
  const [dragOver, setDragOver] = useState(false);
  const toast = useToast();
  const bg = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.300', 'gray.600');
  const hoverBorderColor = useColorModeValue('blue.300', 'blue.500');
  const activeBg = useColorModeValue('blue.50', 'blue.800');
  const iconColor = useColorModeValue('blue.500', 'blue.200');

  const generateKey = async () => {
    const key = await window.crypto.subtle.generateKey(
      {
        name: "AES-CBC",
        length: 256,
      },
      true,
      ["encrypt", "decrypt"]
    );
    return key;
  };

  const encrypt = async (buffer, key) => {
    const iv = window.crypto.getRandomValues(new Uint8Array(16));
    const encrypted = await window.crypto.subtle.encrypt(
      {
        name: "AES-CBC",
        iv,
      },
      key,
      buffer
    );
    return { iv, encryptedData: new Uint8Array(encrypted) };
  };

  const decrypt = async (encryptedData, keyHex, ivHex) => {
    const iv = new Uint8Array(Buffer.from(ivHex, 'hex'));
    const keyBuffer = Buffer.from(keyHex, 'hex');
    const key = await window.crypto.subtle.importKey(
      "raw",
      keyBuffer,
      { name: "AES-CBC", length: 256 },
      true,
      ["decrypt"]
    );

    const decrypted = await window.crypto.subtle.decrypt(
      { name: "AES-CBC", iv },
      key,
      encryptedData
    );

    return new Uint8Array(decrypted);
  };

  const handleFileUpload = async (file) => {
    const key = await generateKey();
    const reader = new FileReader();
    reader.onload = async (e) => {
      const arrayBuffer = e.target.result;
      const buffer = new Uint8Array(arrayBuffer);
      const { iv, encryptedData } = await encrypt(buffer, key);

      const formData = new FormData();
      const encryptedBlob = new Blob([encryptedData], { type: 'application/octet-stream' });
      formData.append('file', encryptedBlob, file.name);
      formData.append('walletAddress', walletAddress);


      const exportedKey = await window.crypto.subtle.exportKey("raw", key);
      const keyHex = Buffer.from(exportedKey).toString('hex');
      formData.append('key', keyHex);
      const ivHex = Buffer.from(iv).toString('hex');
      formData.append('iv', ivHex);
      console.log(`Encryption Key: ${keyHex}`);
      console.log(`IV: ${ivHex}`);

      try {
        const response = await axios.post('https://blockchain-for-medicine-production.up.railway.app/api/files', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        toast({
          title: "File Uploaded",
          description: "Your file has been uploaded successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        onUploadSuccess();
        console.log(response.data);
      } catch (error) {
        console.error("Error uploading file", error);
        toast({
          title: "Upload Error",
          description: "There was an error uploading the file.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(false);
    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      const file = files[0];
      handleFileUpload(file);
      e.dataTransfer.clearData();
    }
  };

  return (
    <VStack
      p={5}
      borderWidth="2px"
      borderRadius="md"
      borderColor={dragOver ? hoverBorderColor : borderColor}
      bg={bg}
      w="full"
      spacing={4}
      align="center"
      justify="center"
      transition="all 0.2s ease-in-out"
      _hover={{
        borderColor: hoverBorderColor,
        bg: activeBg
      }}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
    >
      <input type="file" id="file-upload" onChange={(e) => handleFileUpload(e.target.files[0])} hidden />
      <label htmlFor="file-upload">
        <Center
          p={10}
          cursor="pointer"
          borderRadius="md"
          transition="all 0.2s ease-in-out"
          _hover={{
            bg: activeBg,
          }}
          w="full"
        >
          <VStack>
            <Icon as={FaCloudUploadAlt} boxSize={12} color={iconColor} />
            <Text fontSize="md" fontWeight="medium" color={useColorModeValue('gray.600', 'white')}>
              Drag and drop a file here, or click to select a file
            </Text>
          </VStack>
        </Center>
      </label>
    </VStack>
  );
};

export default FileUploadBox;
